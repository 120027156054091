<template>
  <div class="text-container">
    Wie verhandeln Sie den Kaufpreis Ihrer Apotheke richtig? Wir durften dieses
    Thema nicht nur selber beim Kauf und Verkauf unserer Apotheken erleben,
    sondern haben hier jahrelange Erfahrungen bei Apothekenverkäufen, die wir
    begleitet haben.
  </div>
</template>

<script>
export default {
  metaInfo: {
    meta: [
      {
        name: "description",
        content:
          "Wir helfen Ihnen bei der Verhandlung des Kaufpreises für Ihre Apotheke. Profitieren Sie von unseren Erfahrungen bei Apothekenverkäufen.",
      },
    ],
  },
};
</script>